<template>
  <div>
    <van-dialog
      cancel-button-text="取消"
      confirm-button-text="确认"
      v-model="editRateDialog"
      title="费率设置"
      :show-cancel-button="false"
      :before-close="editRateBefore"
    >
      <!-- <van-field
        v-for="rate in rateList"
        :key="rate.bank_name"
        v-model="rate.rate"
        :name="rate.bank_name"
        :label="rate.name"
        :placeholder="rate.bank_name"
        @input="
          /^(0(\.\d{0,4})?|1(\.0{0,4})?)$/.test(rate.rate)
            ? ''
            : (rate.rate = 0)
        "
        type="number"
        :rules="[{ required: true, message: '请填写费率' }]"
      /> -->

      <van-field
        label="渠道"
        type="number"
        :rules="[{ required: true, message: '请填写费率' }]"
      >
        <template #input>
          <p class="van-field__label" style="font-size: 14px; margin: 0">
            费率
          </p>
        </template>
        <template #right-icon>
          <p class="van-field__label" style="font-size: 14px; margin: 0">
            开关
          </p>
        </template>
      </van-field>
      <van-field
        v-for="rate in rateList"
        :key="rate.bank_name"
        v-model="rate.rate"
        :name="rate.bank_name"
        :label="rate.name"
        :placeholder="rate.bank_name"
        @input="
          /^(0(\.\d{0,4})?|1(\.0{0,4})?)$/.test(rate.rate)
            ? ''
            : (rate.rate = 0)
        "
        type="number"
        :rules="[{ required: true, message: '请填写费率' }]"
      >
        <template #right-icon>
          <p class="van-field__label" style="font-size: 14px">
            <van-switch
              :value="rate.enable == 1"
              @change="rate.enable == 1 ? (rate.enable = 0) : (rate.enable = 1)"
              active-color="#009aaa"
              inactive-color="#cccccc"
              size="20"
            />
          </p>
        </template>
      </van-field>
    </van-dialog>
    <van-dialog v-model="show" title="创建成功">
      <van-cell-group>
        <van-cell title="名称" :value="currentData.name" />
        <van-cell title="账号" :value="currentData.username" />
        <van-cell title="密码" :value="currentData.password" />
        <van-cell title="提现密码" :value="currentData.withdraw_password" />
      </van-cell-group>

      <van-button
        v-clipboard:copy="
          '名称:' +
          currentData.name +
          '\r\n' +
          '账号: ' +
          currentData.username +
          '\r\n' +
          '密码: ' +
          currentData.password +
          '\r\n' +
          '提现密码: ' +
          currentData.withdraw_password
        "
        v-clipboard:success="onCopy"
        style="margin-bottom: 15px"
        plain
        hairline
        type="info"
        block
        >复制</van-button
      >
    </van-dialog>
    <!-- top -->
    <van-sticky>
      <van-nav-bar
        title="创建下线"
        left-arrow
        @click-left="$router.push('/selfData')"
      >
        <template #left>
          <van-icon name="arrow-left" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <!-- top -->
    <div
      class="wrap"
      :style="{
        backgroundImage:
          'url(' +
          $returnColor().path +
          ($store.state.selfData.member_id
            ? '/home-page_bg.png'
            : '/home-page_bg.png') +
          ')',
      }"
    >
      <van-form :submit-on-enter="false" class="formWrap">
        <van-field
          style="border-radius: 10px 10px 0 0"
          v-model="registerForm.username"
          name="账号"
          label="账号"
          placeholder="账号"
          :rules="[{ required: true, message: '请填写账号' }]"
        />
        <van-field
          v-model="registerForm.name"
          name="用户名"
          label="用户名"
          placeholder="用户名"
          :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field
          v-model="registerForm.sub_limit"
          type="number"
          name="可创下线数量"
          label="可创下线数量"
          placeholder="下线数量"
          :rules="[{ required: true, message: '请填写可创下线数量' }]"
        />
        <van-field name="switch" :label="'可' + $returnBuySel('进货')">
          <template #input>
            <van-switch
              active-color="#3FBBFE"
              inactive-color="#cccccc"
              v-model="registerForm.can_payment"
              size="20"
            />
          </template>
        </van-field>
        <van-field name="switch" label="可提现">
          <template #input>
            <van-switch
              active-color="#3FBBFE"
              inactive-color="#cccccc"
              v-model="registerForm.can_withdraw"
              size="20"
            />
          </template>
        </van-field>
        <van-field
          v-model="registerForm.email"
          type="email"
          name="email"
          label="信箱"
          placeholder="email"
        />
        <van-field
          v-model="registerForm.phone"
          type="phone"
          name="电话"
          label="电话"
          placeholder="电话"
        />
        <van-field
          v-model="registerForm.qq"
          type="QQ"
          name="QQ"
          label="QQ"
          placeholder="QQ"
        />
        <van-field
          style="border-radius: 0 0 10px 10px"
          v-model="registerForm.wechat"
          type="wechat"
          name="微信"
          label="微信"
          placeholder="微信"
        />
        <div style="margin: 16px" class="submitBtn">
          <van-button
            :color="$returnColor().color3"
            style="width: 88px; height: 34px"
            @click="confirmRegister"
            type="info"
            native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      registerForm: {
        can_withdraw: true,
        can_payment: true,
      },
      show: false,
      currentData: {},
      editRateDialog: false,
      rateList: [],
      saveRateList: [],
      currentRate: {},
    };
  },
  methods: {
    editRateBefore(method, done) {
      if (method == "confirm") {
        let sendData = [];
        this.rateList.forEach((e) => {
          sendData.push(e);
        });
        if (!sendData.length) {
          done();
          return;
        }
        this.$http
          .post(`/mobile/subs/${this.currentData.id}/rate`, {
            update: sendData,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.$toast.success("成功");
              done();
            } else {
              done(false);
            }
          })
          .catch(() => {
            done(false);
          });
      } else {
        done();
      }
    },
    editRate(data) {
      this.currentRate = JSON.parse(JSON.stringify(data));
      this.$http.get(`/mobile/subs/${data.id}/rate`).then((res) => {
        if (res.data.code == 0) {
          let resData = res.data.data;
          if (Array.isArray(resData)) {
            this.rateList = res.data.data;
          } else {
            try {
              this.rateList = Object.values(res.data.data);
            } catch {
              console.error("nini");
            }
          }
          // this.saveRateList = JSON.parse(JSON.stringify(this.rateList));
          this.editRateDialog = true;
        }
      });
    },
    onCopy() {
      this.$toast.success("已复制");
    },
    confirmRegister() {
      this.registerForm.status = 1;
      let sendData = JSON.parse(JSON.stringify(this.registerForm));
      sendData.can_withdraw
        ? (sendData.can_withdraw = 1)
        : (sendData.can_withdraw = 0);
      sendData.can_payment
        ? (sendData.can_payment = 1)
        : (sendData.can_payment = 0);
      this.$http.post(`/mobile/subs`, sendData).then((res) => {
        if (res.data.code == 0) {
          this.registerForm = {
            can_withdraw: 1,
            can_payment: 1,
          };
          if (this.$store.state.selfData.hide_team != 1) {
            this.editRate(res.data.data);
          }
          this.currentData = res.data.data;
          this.show = true;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  // background-image: url("/home-page_bg.png");
  background-position: top;
  background-size: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  font-size: 14px;
  display: flex;
  justify-content: center;
}
.formWrap {
  width: 285px;
  border-radius: 10px;
}
.submitBtn {
  display: flex;
  justify-content: center;
  font-size: 16px;
}

.dialogImg {
  position: absolute;
  width: 109px;
  top: -67px;
  right: 32px;
  z-index: -1;
}
</style>
